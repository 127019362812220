<template>
  <side-content>
    <template v-slot:header>
      Informações Gerais
      <v-spacer />
      <v-menu offset-y left>
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            small
            class="ml-0 mr-4"
            v-on="on">
            <v-icon
              size="26px"
              color="darkGray">
              {{ $root.icons.layout.more_y }}
            </v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item
            v-if="['initialProposal'].includes(caseRecord.state)"
            @click="openModalNegotiation">
            <v-list-item-title class="caption">
              Mover para Negociação
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="['initialProposal', 'negotiation', 'mediation'].includes(caseRecord.state) && isNegotiator"
            @click="openModalClose">
            <v-list-item-title class="caption">
              Encerrar Caso
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="['initialProposal', 'negotiation', 'mediation'].includes(caseRecord.state) && isNegotiator"
            @click="openModalFinish">
            <v-list-item-title class="caption">
              Finalizar Caso
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="['initialProposal', 'negotiation', 'mediation'].includes(caseRecord.state) && isNegotiator"
            @click="openModalRefuse">
            <v-list-item-title class="caption">
              Recusa Externa
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="['initialProposal', 'negotiation', 'mediation'].includes(caseRecord.state) && (isNegotiator || isAdministrator)"
            @click="openModalDrop">
            <v-list-item-title class="caption">
              Acordo Externo
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="proposal && proposal.state && ['directNegotiation'].includes(caseRecord.state) && (isNegotiator || isAdministrator)"
            @click="directNegotiationAccept">
            <v-list-item-title class="caption">
              Caso foi Aceito
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="proposal && proposal.state && ['directNegotiation'].includes(caseRecord.state) && (isNegotiator || isAdministrator)"
            @click="directNegotiationRefuse">
            <v-list-item-title class="caption">
              Caso foi Recusado
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn
        class="ml-0"
        color="#E5E5EA"
        fab
        depressed
        x-small
        :to="{ name: 'CaseShow' }">
        <v-icon color="primary">
          close
        </v-icon>
      </v-btn>
    </template>

    <h2 class="subtitle-1 textColor--text mb-4">
      Informações sobre o caso
    </h2>
    <v-list dense>
      <list-item label="Criação" :value="$d(caseRecord.insertedAt.toDate(), 'short_date')" divider />
      <list-item label="Campanha" :value="event.name" divider />
      <list-item label="Valor original" :value="$n(caseRecord.originalValue, 'currency')" divider />
      <list-item label="Valor atual" :value="$n(caseRecord.currentValue, 'currency')" divider />
      <list-item label="Atualização" :value="$d(caseRecord.updatedAt.toDate(), 'short_date')" />
    </v-list>

    <h2 class="subtitle-1 textColor--text my-4">
      Informações para cálculo
    </h2>
    <v-list dense>
      <list-item label="Correção monetária" :value="event.calculateParams.correctionIndex" divider />
      <list-item label="Multa moratória" :value="event.calculateParams.fine + '%'" divider />
      <list-item label="Juros moratórios" :value="event.calculateParams.interestRate + '%'" divider />
      <list-item label="Juros do Parcelamento" :value="event.calculateParams.rateInstallments + '%'" divider />
      <list-item label="Honorários Advocatícios" :value="event.calculateParams.fees + '%'" divider />
      <list-item label="Repassar despesas" :value="event.calculateParams.passExpenses" />
    </v-list>

    <h2 class="subtitle-1 textColor--text my-4">
      Sobre o convidado
    </h2>
    <v-list dense>
      <list-item label="Nome" :value="caseRecord.recipient.name" divider />
      <list-item label="CPF" :value="caseRecord.recipient.document" divider />
      <list-item v-if="caseRecord.recipient.subscription" label="Inscrição" :value="caseRecord.recipient.subscription" divider />
      <list-item label="E-mail" :value="caseRecord.recipient.email" divider />
      <list-item label="Telefone" :value="caseRecord.recipient.phone" divider />
      <list-item label="CEP" :value="caseRecord.recipient.address.zipCode" divider />
      <list-item label="Endereço" :value="caseRecord.recipient.address.address" divider />
      <list-item label="Número" :value="caseRecord.recipient.address.number" divider />
      <list-item label="Complemento" :value="caseRecord.recipient.address.complement" divider />
      <list-item label="Bairro" :value="caseRecord.recipient.address.neighborhood" divider />
      <list-item label="Cidade" :value="caseRecord.recipient.address.city" divider />
      <list-item label="Estado" :value="caseRecord.recipient.address.uf" divider />
    </v-list>

    <modal
      ref="modal"
      btn-primary-label="Baixar Caso"
      btn-secondary-color="Cancelar"
      :show="showModalDrop"
      :valid="!disabledButton"
      @close="closeModalDrop"
      @submit="confirmDropCase">
      <span slot="title">Acordo Externo</span>
      <div slot="content">
        <p>O caso baixado será imediatamente inativado.</p>
        <v-form
          ref="form"
          v-model="valid"
          :lazy-validation="lazy">
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="amount"
                v-money="money"
                label="Valor*"
                prefix="R$"
                :rules="currencyRules"
                hint="digite o valor que foi negociado"
                required />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field v-model.number="parcel" label="Parcela" :rules="requiredRules" hint="digite a quantidade de parcelas" />
            </v-col>
          </v-row>
        </v-form>
      </div>
    </modal>

    <modal
      ref="modal"
      btn-primary-label="Recusar Caso"
      btn-secondary-color="Cancelar"
      :show="showModalRefuse"
      :valid="!disabledButton"
      @close="closeModalRefuse"
      @submit="confirmRefuse">
      <span slot="title">Recusa Externa</span>
      <div slot="content">
        <p>O caso recusado será imediatamente finalizado.</p>
        <v-form
          ref="form"
          v-model="valid"
          :lazy-validation="lazy">
          <v-row>
            <v-col cols="12" md="6">
              <v-select
                v-model="reason"
                autocomplete="false"
                item-text="label"
                item-value="value"
                :items="refuseItems"
                label="Informe o motivo da recusa *"
                required />
            </v-col>
          </v-row>
          <v-row v-if="reason === 'other'">
            <v-col cols="12" md="6">
              <v-text-field
                v-model="note"
                label="Descreva o motivo da recusa *"
                required />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="email"
                name="email"
                label="Email para termo de encerramento"
                type="email"
                data-cy="email"
                :rules="emailRules" />
            </v-col>
          </v-row>
        </v-form>
      </div>
    </modal>

    <modal
      ref="modal"
      btn-primary-label="Continuar"
      btn-secondary-color="Cancelar"
      :show="showModalNegotiation"
      :valid="!disabledButton"
      @close="closeModalNegotiation"
      @submit="confirmNegotiation">
      <span slot="title">Mover para negociação</span>
      <div slot="content">
        <v-form
          ref="form"
          v-model="valid"
          :lazy-validation="lazy">
          <v-row>
            <v-col cols="12" md="12">
              <v-textarea v-model="reason" label="Digite o motivo pelo qual o caso será movido pra negociação" />
            </v-col>
          </v-row>
        </v-form>
      </div>
    </modal>

    <modal
      ref="modal"
      btn-primary-label="Finalizar Caso"
      btn-secondary-color="Cancelar"
      :show="showModalFinish"
      :valid="!disabledButton"
      @close="closeModalFinish"
      @submit="confirmFinish">
      <span slot="title">Finalizar Caso</span>
      <div slot="content">
        <p>O caso recusado será imediatamente finalizado.</p>
      </div>
    </modal>

    <modal
      ref="modal"
      btn-primary-label="Encerrar Caso"
      btn-secondary-color="Cancelar"
      :show="showModalClose"
      :valid="!disabledButton"
      @close="closeModalClose"
      @submit="confirmClose">
      <span slot="title">Encerrar Caso</span>
      <div slot="content">
        <p>Confirme o encerramento para finalizar o caso junto a Plataforma.</p>
      </div>
    </modal>
  </side-content>
</template>

<script>
  import casesMixin from '@/mixins/cases'
  import EventParams from '@/components/ui/events/EventParams'
  import PromiseTermDownload from '@/components/ui/PromiseTermDownload'
  import Modal from '@/components/ui/Modal'
  import validate from '@/mixins/validate'
  import masks from '@/mixins/masks'
  import { mapGetters } from 'vuex'

  export default {
    name: 'CaseShowGeneral',
    components: { EventParams, Modal, PromiseTermDownload },
    mixins: [ casesMixin, validate, masks ],
    data: () => ({
      showModalDrop: false,
      showModalRefuse: false,
      showModalFinish: false,
      showModalClose: false,
      showModalNegotiation: false,
      valid: true,
      lazy: false,
      amount: '',
      parcel: '',
      disabledButton: false,
      reason: '',
      note: '',
      email: '',
      refuseItems: [
        {value: 'unemployment', label: 'Desemprego'},
        {value: 'notEnoughMoney', label: 'Falta de dinheiro'},
        {value: 'healthProblem', label: 'Problemas de Saúde'},
        {value: 'incomeReduction', label: 'Diminuição da renda'},
        {value: 'salaryArrears', label: 'Atrasos Salário'},
        {value: 'other', label: 'Outro'}
      ]
    }),
    computed: {
      ...mapGetters({
        proposal: 'proposal/firstActiveForCase',
        isNegotiator: 'user/isNegotiator',
        isAdministrator: 'user/isAdministrator'
      }),
      event () {
        return this.caseRecord.event
      }
    },
    methods: {
      directNegotiationAccept() {
        this.$store.dispatch('proposal/directNegotiationAccept', { proposalId: this.proposal.id })
      },
      directNegotiationRefuse() {
        this.$store.dispatch('proposal/directNegotiationRefuse', { proposalId: this.proposal.id })
      },
      openModalNegotiation() {
        this.showModalNegotiation = true
      },
      openModalFinish() {
        this.showModalFinish = true
      },
      openModalClose() {
        this.showModalClose = true
      },
      closeModalNegotiation () {
        this.showModalNegotiation = false
      },
      closeModalFinish () {
        this.showModalFinish = false
      },
      closeModalClose () {
        this.showModalClose = false
      },
      confirmNegotiation () {
        this.disabledButton = true
        const caseId = this.$route.params.caseId

        return this
          .$store
          .dispatch('case/goToNegotiate', { caseId, reason: this.reason })
          .then(response => {
            this.closeModalNegotiation()
            this.reason = ''
            this.disabledButton = false
            this.$root.$emit('snackbar', {show: true, color: 'success', text: 'Caso movido para negociação com sucesso!'})
            this.$router.push({ name: 'CaseShow', params: { caseId }})
          }).catch(_ => this.disabledButton = false)
      },
      confirmFinish () {
        this.disabledButton = true
        return this
          .$store
          .dispatch('proposal/finish', { proposalId: this.proposal.id })
          .then(response => {
            this.closeModalFinish()
          }).finally(_ => this.disabledButton = false)
      },
      confirmClose () {
        this.disabledButton = true
        const caseId = this.$route.params.caseId

        return this
          .$store
          .dispatch('case/close', caseId)
          .then(response => {
            this.closeModalClose()
            this.disabledButton = false
            this.$root.$emit('snackbar', {show: true, color: 'success', text: 'Caso encerrado com sucesso!'})
            this.$router.push({ name: 'CaseShow', params: { caseId }})
          }).catch(_ => {
            this.disabledButton = false
            this.$root.$emit('snackbar', {show: true, color: 'error', text: 'Houve um erro ao encerrar o caso, tente novamente! </br>Se o problema persistir informe a Mais Câmara', timeout: 5000})
          })
      },
      openModalRefuse() {
        this.showModalRefuse = true
      },
      closeModalRefuse () {
        this.showModalRefuse = false
      },
      confirmRefuse () {
        const caseId = this.$route.params.caseId

        if(!this.reason || (this.reason === 'other' && !this.note)) {
          return
        }

        const data = {
          caseId,
          state: 'externalRefused',
          refuse: {
            reason: this.reason,
            note: this.note,
            email: this.email
          }
        }

        this.disabledButton = true
        return this
          .$store
          .dispatch('case/refuseExternal', data)
          .then(response => {
            this.reason = ''
            this.note = ''
            this.email = ''
            this.closeModalRefuse()
            this.$root.$emit('snackbar', {show: true, color: 'success', text: 'Caso recusado com sucesso!'})
            this.$router.push({ name: 'CaseShow', params: { caseId }})
          }).catch(_ => {
            this.disabledButton = false
            this.$root.$emit('snackbar', {show: true, color: 'error', text: 'Houve um erro ao recusar o caso, tente novamente! </br>Se o problema persistir informe a Mais Câmara', timeout: 5000})
          })
      },
      openModalDrop() {
        this.showModalDrop = true
      },
      closeModalDrop () {
        this.showModalDrop = false
      },
      confirmDropCase () {
        const caseId = this.caseRecord.id
        const amount = this.amount
        const parcel = this.parcel
        this.disabledButton = true

        if (this.amount && this.parcel) {
          this.$store.dispatch('case/drop', { caseId, amount, parcel }).then(() => {
            this.showModalDrop = false
            this.amount = ''
            this.parcel = ''
            this.$router.push({ name: 'Cases', params: { type: 'todos'} })
            this.$root.$emit('snackbar', {show: true, color: 'success', text: 'Acordo externo efetuado com sucesso'})
          }).finally(_ => this.disabledButton = false)
        }
      }
    }
  }
</script>
