<template>
  <div>
    <v-progress-circular
      v-if="loading"
      indeterminate
      color="primary" />

    <v-btn
      v-else
      :href="fileUrl"
      target="blank"
      depressed
      small
      color="accent">
      Visualizar Termo
    </v-btn>
  </div>
</template>

<script>
  import { processData } from '@/utils'

  export default {
    props: {
      promise: Promise
    },
    data: () => ({
      loading: true
    }),
    asyncComputed: {
      async fileUrl () {
        this.loading = true
        const response = await this.promise
        const storage = this.firebase.storage()
        const pathReference = storage.ref(response.data().file)
        const fileUrl = await pathReference.getDownloadURL()
        this.loading = false
        return fileUrl
      }
    }
  }
</script>
